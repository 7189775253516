import React, { useEffect, useState } from 'react'
import axios from 'axios'

export default function Businesses() {

  useEffect(() => {
    getBusinesses()
  }, [])

  const [users, setUsers] = useState([])

  const getBusinesses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/business/get-businesses`)
      .then((res) => {
        console.log(res.data)
        setUsers(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div>
      
      <h2 style={{textAlign:'center'}}>{users.length} business users</h2>
      <table className='user-table'>
        <tr>
          <th>Business</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
        </tr>

        {
          users.map((user, i) => {
            return(
              <tr key={i}>
                <td>{user.companyName}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
              </tr>
            ) 
          })

        }
      </table>
    </div>
  )
}
