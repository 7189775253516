import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify'

const label = { inputProps: { 'aria-label': 'Size switch demo' } };


export default function Courses() {

  const [courseList, setCourseList] = useState([])

  useEffect(() => {
    getCourses()
  } , [])

  const getCourses = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/course/get-all-courses`)
      .then((res) => {
        setCourseList(res.data)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handlePublish = (_id, published) => {
    console.log(published)
    axios.post(`${process.env.REACT_APP_API_URL}/course/publish-course`, {_id, published})
      .then(() => {
        toast(published ? "Successfully published" : "Successfully unpublished")
        getCourses()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div style={{textAlign:'center'}}>

      <Link to={'/courses/new'}>
        <button className='admin-btn'>
          Add new course
        </button>
      </Link>

      {courseList.length < 1 ?
      <p>No courses added</p>
    :
    <table className='user-table'>
        <tr>
          <th>Course</th>
          <th>Published</th>
          
        </tr>

        {
          courseList.map((course, i) => {
            return(
              <tr key={i}>
                <td>{course.title}</td>
                <td>
                  <Switch {...label} checked={course.published} onChange={() => handlePublish(course._id, !course.published)} color="success"/>
                </td>
            
              </tr>
            ) 
          })

        }
      </table>
        }
    </div>
  )
}
