import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import { ref, deleteObject } from "firebase/storage";
import { storage } from '../Firebase';

export default function Dashboard() {

  useEffect(() => {
    getUsersToBeVerified()
  },[])

  const [users, setUsers] = useState([])
  const [id, setId] = useState("")


  const getUsersToBeVerified = () => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/freelance/get-freelancers-to-be-verified`)
    .then((res) => {
      console.log(res.data)
      setUsers(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const verifyAccount = (userId) => {
    axios
    .post(`${process.env.REACT_APP_API_URL}/freelance/verify-id`, {userId})
    .then((res) => {
      getUsersToBeVerified()
      deleteId(userId)

    })
    .catch((e) => {
      console.log(e)
    })
  }

  const declineVerification = (userId) => {
    axios
    .post(`${process.env.REACT_APP_API_URL}/freelance/decline-verification`, {userId})
    .then((res) => {
      getUsersToBeVerified()
      deleteId(userId)
    })
    .catch((e) => {
      console.log(e)
    })

  }



  const deleteId = (userId) => {

    const imgRef = ref(storage, `freelancers/verification/${userId}`);

    deleteObject(imgRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });

  }

  return (
    <div style={{padding: '1rem'}}>
      <h2>Accounts to be verified</h2>
      {users.length < 1 ?
      <p>No users to be verified</p>
      :
      <table>
        <tr>
          <th>Name</th>
          <th>DOB</th>
          <th>ID</th>
          <th>Respond</th>


        </tr>
        {users.map((user, i) => {
        return <tr key={i} >
          <td>{user.firstName} {user.lastName}</td>
          <td>{moment(user.dob).format("DD/MM/YYYY")}</td>
          <td>
            <button className='viewID-btn' onClick={() => setId(user.verificationId)}>View ID</button>
          </td>
          <td>
            <button onClick={() => verifyAccount(user._id)}>Verify</button>
            <button onClick={() => declineVerification(user._id)}>Decline</button>

          </td>
          
          </tr>
      })}

      </table>
      
      }

      {id && 
      <div id='id-modal'>
        <img src={id} style={{width: '100%'}}/>
        <button onClick={()=> setId("")}>Close</button>
      </div>
      }

    </div>
  )
}
