import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import {  signOut } from "firebase/auth";
import { auth } from '../Firebase';
import logo from '../advidity.png'

export default function AdminUI() {

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div>
        <nav>
            <Link to={'/'}><img style={{height: 40}} src={logo}/></Link>
            

            <div>
              <Link to={'/freelancers'}>Freeancers</Link>
              <Link to={'/businesses'}>Businesses</Link>
              <Link to={'/courses'}>Courses</Link>
              <Link to={'/registered'}>Registered</Link>

              <button className='admin-btn' style={{marginLeft:'1rem'}} onClick={handleLogout}>Logout</button>
            </div>
        </nav>

        <Outlet />
    </div>
  )
}
