import './App.css';
import Login from './Components/Login';
import RequireAuth from './Components/RequireAuth'
import { AuthProvider } from './Context/AuthContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminUI from './Components/AdminUI';
import Dashboard from './Components/Dashboard';
import Freelancers from './Components/Freelancers';
import Businesses from './Components/Businesses';
import Courses from './Components/Courses';
import NewCourse from './Components/NewCourse';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Registered from './Components/Registered';

function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes>
        
        {/*Public routes */}
        <Route path='/login' element={<Login />} />

        {/*Admin routes */}
        <Route element={<RequireAuth  />} >
          <Route path='/' element={<AdminUI />} >
            <Route index element={<Dashboard />}/>
            <Route path='/freelancers' element={<Freelancers />}/>
            <Route path='/businesses' element={<Businesses />}/>
            <Route path='/courses' element={<Courses />}/>
            <Route path='/courses/new' element={<NewCourse />}/>
            <Route path='/registered' element={<Registered />}/>

          </Route>
        </Route>

      </Routes>
    
    <ToastContainer position='top-center'/>

    </BrowserRouter>  
    </AuthProvider>  
  );
}

export default App;
