import React, { useState } from 'react'
import { toast } from 'react-toastify'
import './NewCourse.css'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

export default function NewCourse() {

    let navigate = useNavigate()

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [points, setPoints] = useState("")
    const [questions, setQuestions] = useState([])
    const [question, setQuestion] = useState("")
    const [answers, setAnswers] = useState([])
    const [answer, setAnswer] = useState("")
    const [correctAnswer, setCorrectAnswer] = useState("")
    const [video, setVideo] = useState("")

    //modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addQuestion = () => {
        if(!question || answers.length < 2 || !correctAnswer){
            toast.error("Please add question and answers")
        } else {
            setQuestions(prev => [...prev, {question, video, answers, correctAnswer}])
            setAnswers([]);setQuestion("");setVideo("");setCorrectAnswer("")
            handleClose()
        }
    }

    const addAnswer = () => {
        if(!answer){
            return
        } else {
            setAnswers(prev => [...prev, answer])
            setAnswer("")
        }
    }

    const createCourse = () =>{
        axios
        .post(`${process.env.REACT_APP_API_URL}/course/create-course`, 
        {title, description, points, questions, published: false})
        .then(()=> {
            toast.success("Successfully added")
            navigate('/courses')
        })
        .catch((e) => {
            toast.error("Oops, somethng went wrong")
        })
    }

  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width: 1200, maxWidth: '95vw', margin: ' 1rem auto'}}>
        <h1>Create new course</h1>

        <TextField id="outlined-basic" margin="normal" sx={{width: 600, maxWidth: '95vw'}} label="Course Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)}/>
        <TextField id="outlined-basic" margin="normal" sx={{width: 600, maxWidth: '95vw'}} label="Course Decription" multiline rows={3} variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)}/>
        <TextField id="outlined-basic" margin="normal" sx={{width: 600, maxWidth: '95vw'}} label="Points for completion" variant="outlined" value={points} onChange={(e) => setPoints(e.target.value)}/>

        <Button onClick={handleOpen} variant='contained'  sx={{backgroundColor:'#272838', my:2}}>Add question</Button>

        {questions.map((item, i) => {
            return <Accordion sx={{width: 600, maxWidth: '95vw'}} key={i}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField id="outlined-basic" margin="normal" fullWidth label="Video URL" variant="outlined" value={item.video}/>
                            <FormControl fullWidth>
                            <FormLabel id="demo-radio-buttons-group-label">Answers</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={item.correctAnswer}
                                name="radio-buttons-group"
                                
                            >
                                {item.answers.map((item, i) => {
                                    return <FormControlLabel key={i} value={item} control={<Radio />} label={item} />

                                })}
                                
                            </RadioGroup>
                            </FormControl> 
                        </AccordionDetails>
                    </Accordion>
        })}
        
     
      
        
        

            <Button onClick={createCourse}  variant='contained' sx={{backgroundColor:'#272838', my:2}}>Save draft and close</Button>


        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <TextField id="outlined-basic" margin="normal" fullWidth label="Video URL" variant="outlined" value={video} onChange={(e) => setVideo(e.target.value)}/>
            <TextField id="outlined-basic" margin="normal" fullWidth label="Question" variant="outlined" value={question} onChange={(e) => setQuestion(e.target.value)}/>
            <TextField id="outlined-basic" margin="normal" label="Answer" variant="outlined" value={answer} onChange={(e) => setAnswer(e.target.value)}/>

            <Fab aria-label="add" onClick={addAnswer} sx={{m: '1rem'}}>
                <AddIcon />
            </Fab>      

            <FormControl fullWidth>
            <FormLabel id="demo-radio-buttons-group-label">Answers</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                onChange={(e) => setCorrectAnswer(e.target.value)}
            >
                {answers.map((item, i) => {
                    return <FormControlLabel key={i} value={item} control={<Radio />} label={item} />

                })}
                
            </RadioGroup>
            </FormControl>          
            
            <Button sx={{backgroundColor:'#272838', marginTop: 2}} variant='contained' onClick={addQuestion}>Save question</Button>
        </Box>
      </Modal>
      {console.log(questions)}
    </div>
  )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };