import React, { useState, useEffect } from 'react'
import './Login.css'
import {  signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { auth } from '../Firebase';
import { useAuth } from '../Context/AuthContext';

export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  let { currentUser } = useAuth()
  
    let navigate = useNavigate()

    useEffect(() => {
      if(currentUser){
        navigate('/')
      } else {
        return
      }
    }, [ currentUser])

  const handleLogin = () => {
    if(!email || !password){
      toast.error("Please input all fields")
    } else {
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user)
      
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }

  return (
    <div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>

        <div id='login-container'>
            <h2>Advidity login</h2>
            <input placeholder='Email' type='text' className='login-input' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input placeholder='Password' type='password' className='login-input' value={password} onChange={(e) => setPassword(e.target.value)}/>
            <button className='admin-btn' onClick={handleLogin}>Login</button>
        </div>

    </div>
  )
}
