import React, { useEffect, useState} from 'react'
import axios from 'axios'

export default function Registered() {

    useEffect(() => {
        getInterested()
    }, [])

    const getInterested = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-interested`)
        .then((res) =>{
            setInterested(res.data)
        })
        .catch((e) => {

        })
    }

    const [interested, setInterested] = useState([])

  return (
    <div>
        <table style={{margin: '1rem auto'}}>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Account type</th>
            </tr>

            {interested.map((person, i) => {
                return (
                    <tr key={i}>
                        <td>{person.name}</td>
                        <td>{person.email}</td>
                        <td>{person.account}</td>
                    </tr>
                )
            })}
        </table>
    </div>
  )
}
